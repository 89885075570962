
import { createStore } from 'vuex'

export default createStore({
  state: {
    visible:false,
    bright:true,
    userName:'Fitment Admin', //用户名字
    header_Img:'https://ytskkj.com/vendor/dcat-admin/images/logo.png',//用户头像
    refresh: false, // 刷新页面
    themeConfig: {
      primary: 'DEFAULT_PRIMARY',
      isDark: false,
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    changeCollapse() {
      this.collapse = !this.collapse
    },
    setRefresh() {
      this.refresh = !this.refresh
    },
    setThemeConfig(themeConfig) {
      this.themeConfig = themeConfig
    }
  },
  persist: true,
  modules: {
  }
})
