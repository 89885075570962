/*
 * @Author: ckz 1498632919@qq.com
 * @Date: 2023-03-01 10:19:33
 * @LastEditors: ckz 1498632919@qq.com
 * @LastEditTime: 2024-08-19 15:22:08
 * @FilePath: \ckz\vue-shenbao\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
const router = createRouter({
  history: createWebHashHistory(), // createWebHashHistory 为哈希模式的路由，如果需要选择 histiry 模式，可以用 createWebHistory 方法。
  routes: [
    {
      path: '/',
      component: () => import('@/views/index/index.vue')
    },
    {
      path: '/login',
      name: 'loginList',
      component: () => import('@/views/login/login.vue')
    },
    {
      path: '/index',
      name: 'indexList',
      component: () => import('@/views/index/index.vue'),
      children: [
         // 主页
         {
          path: "/Home",
          name: "HomeList",
          component: () => import("@/views/index/Home/Home.vue"),
        },
        // 公司管理
        {
          path: "/Companies",
          name: "CompaniesList",
          component: () => import("@/views/index/Companies/Companies.vue"),
        },
        // 用户管理
        {
          path: "/Users",
          name: "UsersList",
          component: () => import("@/views/index/Users/Users.vue"),
        },
        // 工地管理
        {
          path: "/Works",
          name: "WorksList",
          component: () => import("@/views/index/Works/Works.vue"),
        },
        // 装修日志
        {
          path: "/Logs",
          name: "LogsList",
          component: () => import("@/views/index/Logs/Logs.vue"),
        },
        // 商机管理
        {
          path: "/Chances",
          name: "ChancesList",
          component: () => import("@/views/index/Chances/Chances.vue"),
        },
        // 案例管理
        {
          path: "/Cases",
          name: "CasesList",
          component: () => import("@/views/index/Cases/Cases.vue"),
        },
        // 设备列表
        {
          path: "/Cameras",
          name: "CamerasList",
          component: () => import("@/views/index/Cameras/Cameras.vue"),
        },
        // 预算模板
        {
          path: "/Budgets",
          name: "BudgetsList",
          component: () => import("@/views/index/Budgets/Budgets.vue"),
        },
        // 保修卡
        {
          path: "/Cards",
          name: "CardsList",
          component: () => import("@/views/index/Cards/Cards.vue"),
        },
        // 评论管理
        {
          path: "/Comments",
          name: "CommentsList",
          component: () => import("@/views/index/Comments/Comments.vue"),
        },
        // 计划模板
        {
          path: "/Plan-Templates",
          name: "Plan-TemplatesList",
          component: () => import("@/views/index/Plan-Templates/Plan-Templates.vue"),
        }
      ]
    },
  ]
})

export default router


